// import Swiper JS
import Swiper from "swiper";
import { Scrollbar, Navigation } from "swiper/modules";
// import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/scrollbar.min.css";

var swiperHero = new Swiper(".init-swiper-hero", {
  modules: [Scrollbar, Navigation],
  centeredSlides: true,
  loop: true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    // when window width is >= 480px
    640: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    720: {
      slidesPerView: 1.8,
      spaceBetween: 16,
    },
    // when window width is >= 640px
    940: {
      slidesPerView: 2.4,
      spaceBetween: 16,
    },
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
  },
  navigation: {
    nextEl: ".swiper-hero-button-next",
    prevEl: ".swiper-hero-button-prev",
  },
});

var swiperInterior = new Swiper(".init-swiper-interior", {
  modules: [Scrollbar, Navigation],
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    // when window width is >= 480px
    640: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    720: {
      slidesPerView: 2.4,
      spaceBetween: 16,
    },
    // when window width is >= 640px
    940: {
      slidesPerView: 3.5,
      spaceBetween: 16,
    },
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
  },
  navigation: {
    nextEl: ".swiper-interior-button-next",
    prevEl: ".swiper-interior-button-prev",
  },
});

var swiperExterior = new Swiper(".init-swiper-exterior", {
  modules: [Scrollbar, Navigation],
  loop: true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    // when window width is >= 480px
    640: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    720: {
      slidesPerView: 2.2,
      spaceBetween: 16,
    },
    // when window width is >= 640px
    940: {
      slidesPerView: 2.2,
      spaceBetween: 16,
    },
  },
  navigation: {
    nextEl: ".swiper-exterior-button-next",
    prevEl: ".swiper-exterior-button-prev",
  },

  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
  },
});

var swiperGarden = new Swiper(".init-swiper-garden", {
  modules: [Scrollbar, Navigation],
  loop: true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    // when window width is >= 480px
    640: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    720: {
      slidesPerView: 2.2,
      spaceBetween: 16,
    },
    // when window width is >= 640px
    940: {
      slidesPerView: 2.2,
      spaceBetween: 16,
    },
  },
  navigation: {
    nextEl: ".swiper-garden-button-next",
    prevEl: ".swiper-garden-button-prev",
  },

  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
  },
});

var swiperActivities = new Swiper(".init-swiper-activities", {
  modules: [Scrollbar, Navigation],
  loop: true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    // when window width is >= 480px
    640: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    720: {
      slidesPerView: 2.4,
      spaceBetween: 16,
    },
    // when window width is >= 640px
    940: {
      slidesPerView: 3.5,
      spaceBetween: 16,
    },
  },
  navigation: {
    nextEl: ".swiper-activities-button-next",
    prevEl: ".swiper-activities-button-prev",
  },

  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
  },
});

swiperHero.slideTo(3, false, false);
